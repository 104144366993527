import React,{useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap"
import { graphql} from "gatsby"
import { useLocation } from "@reach/router"
import { Helmet } from "react-helmet";

import LocRatingMap from "gatsby-theme-starberry-lomondgroup/src/components/map/loc-rating-map-details"
import Layout from "gatsby-theme-starberry-lomondgroup/src/components/Layout"
import Customer from "gatsby-theme-starberry-lomondgroup/src/components/Customer/Customer";
import FindOut from "gatsby-theme-starberry-lomondgroup/src/components/FindOut/FindOut";
import BranchBanner from "gatsby-theme-starberry-lomondgroup/src/components/BranchBanner/BranchBanner";
import BranchInfo from "gatsby-theme-starberry-lomondgroup/src/components/BranchInfo/BranchInfo";
import Counter from "gatsby-theme-starberry-lomondgroup/src/components/Counter/Counter";
import FeaturesOffice from "gatsby-theme-starberry-lomondgroup/src/components/Features/FeaturesOffice";
import Journey from "gatsby-theme-starberry-lomondgroup/src/components/Journey/Journey";
import Reviews from "gatsby-theme-starberry-lomondgroup/src/components/Reviews/Reviews";
import Team from "gatsby-theme-starberry-lomondgroup/src/components/Team/TeamOffice"
import "gatsby-theme-starberry-lomondgroup/src/scss/branch-details.scss";
import ContactForm from "gatsby-theme-starberry-lomondgroup/src/components/ContactForm/BranchContactForm";
import { OfficeDetails } from "gatsby-theme-starberry-lomondgroup/src/common/queries/common_use_query"
import LocalExperience from "gatsby-theme-starberry-lomondgroup/src/components/LocalExperience/LocalExperienceOffice"
import PreLoader from "gatsby-theme-starberry-lomondgroup/src/modules/pre-loader"
import OfficeNews from "gatsby-theme-starberry-lomondgroup/src/components/News/OfficeNews"
import TrustpilotReviews from "gatsby-theme-starberry-lomondgroup/src/components/TrustpilotReviews/TrustpilotReviews";

// markup
const BranchDetails = (props) => {
    const location = useLocation();
    //const[officeDeta, setOfficeDet] = useState("");

    var office = props.data.glstrapi?.office;
    var GQLModules = props.data.glstrapi?.office?.Modules;
    var globalModule = props.data.glstrapi?.globalModule;

    // const { loading, error, data } = OfficeDetails(props.pageContext.slug);

    // useEffect(()=>{
    //     data?.offices?.length > 0 && setOfficeDet(data?.offices[0]);
    // },[data])

    let tab_list = [
        {
            "tab_name": "Property for Sale",
            "add_tab": "residential_sales"
        },
        {
            "tab_name": "Property to Rent",
            "add_tab": "residential_lettings"
        }
    ]

    if(process.env.GATSBY_SITE_NAME === "Stonehouse" || process.env.GATSBY_SITE_NAME === "BV Students" || process.env.GATSBY_SITE_NAME === "Sinclair"){
        tab_list = [
            {
                "tab_name": "Property to Rent",
                "add_tab": "residential_lettings"
            }
        ]
    }

    // const { glstrapi: { office, globalModule } } = data;
    // //console.log('globalModule ==>', globalModule)

    // Get Map Details
    let mapItems = [];
    let mapItem = {};
    mapItem['lat']  = office.latitude      
    mapItem['lng']  = office.longitude
    mapItem['id']  = office.id

    const mapMarkerImageShowFlag = 0;
    mapItems.push( mapItem );
    // Get Map Details

    let meta_title = office?.seo_meta?.title ? office?.seo_meta?.title : office?.name ? office?.name : "";

    let meta_desc = office?.seo_meta?.description ? office?.seo_meta?.description : `Our ${office?.name} offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.`;

    let meta_data = meta_title ? {title: `${meta_title}`, description: meta_desc} : "";

    var type = props?.pageContext?.type;

    //console.log("location ==>", location);

    var opening_hours = ""
    office?.opening_hours.length > 0 && office?.opening_hours.map((item) => {
        opening_hours += `${item.name}: ${item.value}, `
    })

    //console.log("opening_hours", opening_hours)
    var ldJson = `{
        "@context": "https://www.schema.org",
        "@type": "RealEstateAgent",
        "name": "${process.env.GATSBY_SITE_NAME} estate agents in ${office.name}",
        "url": "${process.env.GATSBY_SITE_URL}/",
        "logo": "${process.env.GATSBY_SITE_URL}/images/mail-logo.png",
        "image": "${office?.image?.url ? office?.image?.url : ''}",
        "description": "Our ${office?.name} offer the best property advice in Selling, Buying, Letting and Renting. Contact us to get assistance.",
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "${office?.address}"
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": "${office?.latitude ? office?.latitude : ''}",
            "longitude": "${office?.longitude ? office?.longitude : ''}"
        },
        "openingHours": "${opening_hours}",
        "telephone": "${office?.mobile ? office?.mobile : ''}",
        "contactPoint": 	{
            "@type": "ContactPoint",
            "contactType": "Sales",
            "telephone": "${office?.mobile ? office?.mobile : ''}"
        }
    }`

    return (
        // loading ? 
        //     <PreLoader />
        // :
        <div className={`detail-page office_details ${type === "contact-form" ? "search-header contact-field" : ""}`}>
            {office?.name &&
            <Layout seo_meta={meta_data} menu_slug={"office-details"} isJourneySec={type === "contact-form" ? false : true} GQLPage={{layout:"static_template"}}>
                <Helmet>
                    <script  type="application/ld+json">{ldJson}</script>
                </Helmet>

                {type === "contact-form" ?
                    <ContactForm office_data={office} location={location}/>
                :
                <>
                    <BranchBanner office_data={office} location={location} h1_title={office?.name}/>
                    <BranchInfo office_data={office} />

                    {/* {office?.video_section && <Customer Module={office.video_section} GQLPage={office} office_template={true} /> }

                    {(office?.show_statistics && office?.statistics_component?.length > 0) && <Counter Module={office} />} */}

                    {/* {office?.intro_section && <FindOut Module={office?.intro_section} GQLPage={office} /> }     */}

                    <Team Module={{title:`${office?.name} Team`}} team_data={office?.teams} office_team={true} />

                    {
                        (GQLModules?.length > 0 && GQLModules?.map((Module, index) => {
                            return(
                                <>
                                    {Module.__typename === "GLSTRAPI_ComponentComponentsShowFeaturedProperty" &&  Module?.tab_list?.length > 0 && <FeaturesOffice key={Module} Module={Module} office_crm_id={office?.office_crm_id ? office?.office_crm_id?.split(",") : []} tab_list={Module?.tab_list} h1_title={office?.name} defaultTitle={"Featured Properties"} />}
                                </>
                            )
                        })
                        )
                    }
                    
                    {GQLModules?.length > 0 && GQLModules?.map((Module, index) => {
                            return(
                                <>
                                    {Module.__typename === "GLSTRAPI_ComponentComponentsLocalExperience" && <LocalExperience key={Module} Module={Module} GQLPage={office} office_template={true} />}

                                    {Module.__typename === "GLSTRAPI_ComponentComponentsVideoSection" &&  <Customer key={Module} Module={Module} GQLPage={office}/>}

                                    {Module.__typename === "GLSTRAPI_ComponentComponentsTrustpilotComponent" && <TrustpilotReviews key={Module} Module={Module} />}
                                </>
                            )
                        })
                    }

                    {/* {office?.blogs?.length > 0 && 
                        <OfficeNews blogs={office?.blogs} />
                    } */}

                    {/* {office.latitude && office.longitude ?
                        <section className="map-section">
                            <div className="map-img">
                            <Container>
                                <Row>
                                    <Col>
                                        <LocRatingMap data={mapItems} />
                                    </Col>
                                </Row>
                            </Container>
                            </div>
                        </section>
                        : ''
                    } */}

                    {/* <Reviews />  */}
                    {/* <FeaturesOffice office_crm_id={office?.office_crm_id} tab_list={tab_list} h1_title={office?.name} /> */}

                    <Journey />
                </>
                }
            </Layout>
            }
        </div>
        
    )
}

export default BranchDetails

export const query = graphql`
query offficeDetailQueryBV($id: ID!) {
    glstrapi { 
        globalModule {
            branch_statistics {
                id
                icon_class
                heading
                content
            }
        }

        office(id: $id) {
            id
            slug
            publish
            latitude
            longitude
            office_crm_id
            name
            address
            mobile
            email
            show_statistics
            office_type
            areas{
                id
                area_name
            }
            image{
                url
                alternativeText
            }
            imagetransforms
            ggfx_results {
                id
                content_type
                transforms
                src_import_url
                src_cftle
                field
            }
            seo_meta {
                title
                keywords
                description
                id
                image {
                  alternativeText
                  url
                }
            }
            statistics_component {
                heading
                icon_class
                content
                id
            }
            opening_hours{
                id
                name
                value
            }
            area_guides {
                id
                banner_image {
                    url
                    alternativeText
                }
                content
                imagetransforms
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
                latitude
                longitude
                publish
                slug
                title
            } 
            teams(sort: "rank:asc") {
                name
                mobile_no
                id
                category
                email
                image {
                  alternativeText
                  url
                }
                imagetransforms
                designation
                slug
                title
                publish
                content
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }
            blogs(sort:"createdAt:desc", where:{publish:true, categories_nin:["Investments"]}) {
                id
                slug
                title
                embed_video_url
                tile_image {
                  url
                  alternativeText
                }
                tags
                imagetransforms
                ggfx_results {
                    id
                    content_type
                    transforms
                    src_import_url
                    src_cftle
                    field
                }
            }

            intro_section{
                id
                text_block {
                    id
                    title
                    description
                }

                link {
                    id
                    custom_link
                    label
                    link_type
                    target
                    menu_item {
                        slug
                        parent {
                        slug
                        parent {
                            slug
                            parent {
                            slug
                            }
                        }
                        }
                    }
                }
                
            }

            video_section{
                title
                content
                bg_color
                embed_video_url
                image{
                  url
                  alternativeText
                }
                Button_CTA{
                    theme
                    target
                    label
                    custom_link
                    id
                    menu_item {
                      slug
                      parent {
                        slug
                        parent {
                          slug
                          parent {
                            slug
                          }
                        }
                      }
                    }
                }
            }

            Modules {
                __typename

                ... on GLSTRAPI_ComponentComponentsVideoSection {
                    id
                    title
                    content
                    read_more_content
                    embed_video_url
                    bg_color
                    cust_padd:padding
                    vid_layout_type:layout_type
                    vid_img_align:image_align
                    button_CTA:Button_CTA {
                    target
                    theme
                    
                    label
                    custom_link
                    menu_item {
                        slug
                        parent {
                        slug
                        parent {
                            slug
                            parent {
                            slug
                            }
                        }
                        }
                    }
                    }
                    image {
                    url
                    alternativeText
                    }
                }
                
                ... on GLSTRAPI_ComponentComponentsLocalExperience {
                    id
                    title
                    content
                    background_color: bg_color
                    accordian_module {
                        answer
                        question
                    }
                    button_cta {
                        theme
                        target
                        custom_link
                        id
                        label
                        menu_item {
                            slug
                            parent {
                                slug
                                parent {
                                    slug
                                    parent {
                                        slug
                                    }
                                }
                            }
                        }
                    }
                    image {
                        url
                        alternativeText
                    }
                    image_align

                }

                ... on GLSTRAPI_ComponentComponentsTrustpilotComponent {
                    id
                    title
                    content
                    bg_image {
                    url
                    alternativeText
                    }
                    bgclr:bg_color
                }

                ... on GLSTRAPI_ComponentComponentsShowFeaturedProperty {
                    id
                    show
                    title
                    tab_list {
                        add_tab
                        id
                        tab_name
                    }
                    background_clr:bg_color
                    feat_padd:padding
                }
            }
        } 
    }
}`